// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-o-nas-js": () => import("./../../../src/pages/o-nas.js" /* webpackChunkName: "component---src-pages-o-nas-js" */),
  "component---src-pages-przed-wizyta-js": () => import("./../../../src/pages/przed-wizyta.js" /* webpackChunkName: "component---src-pages-przed-wizyta-js" */),
  "component---src-pages-uslugi-chirurgia-js": () => import("./../../../src/pages/uslugi/chirurgia.js" /* webpackChunkName: "component---src-pages-uslugi-chirurgia-js" */),
  "component---src-pages-uslugi-internistyka-js": () => import("./../../../src/pages/uslugi/internistyka.js" /* webpackChunkName: "component---src-pages-uslugi-internistyka-js" */),
  "component---src-pages-uslugi-kardiologia-js": () => import("./../../../src/pages/uslugi/kardiologia.js" /* webpackChunkName: "component---src-pages-uslugi-kardiologia-js" */),
  "component---src-pages-uslugi-okulistyka-js": () => import("./../../../src/pages/uslugi/okulistyka.js" /* webpackChunkName: "component---src-pages-uslugi-okulistyka-js" */),
  "component---src-pages-uslugi-onkologia-js": () => import("./../../../src/pages/uslugi/onkologia.js" /* webpackChunkName: "component---src-pages-uslugi-onkologia-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */)
}

